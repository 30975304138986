<template>
  <div class="order-contaner">
    <!-- 我的设备列表 start -->
    <el-row class="list">
      <!-- 订单标号 -->
      <div class="query-item input order-num">
        <span>订单编号:</span>
        <el-input
          type="text"
          maxlength="20"
          v-model="orderNum"
          placeholder="请输入订单编号"
          autocomplete="off"
        ></el-input>
      </div>
      <!-- 设备型号 -->
      <div class="query-item input order-num">
        <span>设备型号:</span>
        <el-input
          type="text"
          maxlength="20"
          v-model="mobel"
          placeholder="请输入设备型号"
          autocomplete="off"
        ></el-input>
      </div>
      <!-- 设备类型 -->
      <el-row class="query-item check" type="flex" align="middle">
        <el-row class="company">
          <span class="fs14 c6 name">设备类型：</span>
          <!-- <el-select v-model="type" placeholder="请选择">
            <el-option
              v-for="item in comps"
              :key="item.deviceTypeId"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select> -->

          <el-cascader
            placeholder="请选择设备类型"
            :options="options1"
            :props="props1"
            :value="posValue1"
            @change="posHandle1"
          ></el-cascader>
        </el-row>
      </el-row>
      <!-- 设备类型结束 -->
      <!-- 公司名称 -->
      <div class="query-item input compName">
        <span>公司名称:</span>
        <el-input
          type="text"
          maxlength="20"
          v-model="compName"
          placeholder="请输入公司名称"
          autocomplete="off"
        ></el-input>
      </div>
      <!-- 订单状态 开始 -->
      <el-row class="query-item order-box" type="flex" align="middle">
        <el-row class="order">
          <span class="fs14 c6 name">订单状态：</span>
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in statusArr"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-row>
      </el-row>
      <!-- 订单状态结束 -->
      <!-- 按钮 -->
      <!-- <el-button class="search" @click="exportHandle('订单')">导出</el-button> -->
      <!-- 当前设备 -->
      <el-row class="current-box" type="flex" align="middle">
        <p class="num">
          当前拥有 <span>{{ totalNum }}台</span> 设备
        </p>
      </el-row>
      <!-- 表格展示 start -->
      <el-row class="table-box">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
        >
          <el-table-column prop="num" label="订单编号">
             <template slot-scope="{ row }">{{
              row.num || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="comp" label="公司">
             <template slot-scope="{ row }">{{
              row.comp || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="comp" label="故障现象">
             <template slot-scope="{ row }">{{
              row.comp || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="modal" label="设备型号"
            > <template slot-scope="{ row }">{{ row.modal || "-" }}</template>
          </el-table-column>
          <el-table-column prop="brand" label="设备品牌">
             <template slot-scope="{ row }">{{
              row.brand || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="status" label="订单状态">
             <template slot-scope="{ row }">{{
              row.status || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="date" label="下单时间"
            > <template slot-scope="{ row }">{{ row.date || "-" }}</template>
          </el-table-column>
          <el-table-column prop="handle" label="操作" min-width="240">
            <template slot-scope="scope">
              <span @click="handleEdit(scope.$index, scope.row)" class="see p">
                <router-link
                  :to="{ name: 'orderdetail', query: { id: scope.row.id } }"
                  >查看详情</router-link
                >
              </span>
              <!-- 待指派状态时才有的  取消订单 -->
              <span
                @click="handleCancle(scope.$index, scope.row)"
                class="cancle p"
              >
                <el-button
                  v-if="
                    scope.row.orderStatus == 3 || scope.row.orderStatus == 2
                  "
                  type="text"
                  @click="assderVisible = true"
                  style="color: #ff0015"
                  >取消订单</el-button
                >
              </span>
              <!-- 待评价状态才有的  评价 -->
              <span
                @click="handleCommit(scope.$index, scope.row)"
                class="commit p"
              >
                <router-link
                  v-if="scope.row.orderStatus == 6"
                  :to="{ name: 'comment', query: { id: scope.row.id } }"
                  >评价</router-link
                >
              </span>
              <!-- 已完成状态才可能有的 也可能没有  根据状态值判断    维保记录 -->
              <span
                @click="handleWeibao(scope.$index, scope.row)"
                class="commit p"
              >
                <el-button
                  v-if="
                    scope.row.orderStatus == 7 && scope.row.workOrderCount != 0
                  "
                  type="text"
                  class="c3"
                  >维保记录</el-button
                >
              </span>
              <!-- 已完成状态才可能有的 也可能没有  根据状态值判断    合同详情 -->
              <span
                @click="handleDetails(scope.$index, scope.row)"
                class="commit p"
              >
                <el-button
                  v-if="
                    scope.row.orderStatus == 7 && scope.row.contractMoney != 0
                  "
                  type="text"
                  @click="detailDialog = true"
                  class="c3"
                  >合同详情</el-button
                >
              </span>
              <!-- s2新增 -->
              <span
                @click="handleCancle(scope.$index, scope.row)"
                class="cancle p camera"
              >
                <router-link
                  :to="{ name: 'seecamera', query: { id: scope.row.id } }"
                  >查看摄像头</router-link
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 表格展示 end -->
      <!-- 分页开始 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
      <!-- 分页结束 -->
    </el-row>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="750px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">取消订单</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div>
        <!-- 表单开始 -->
        <el-form
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!--联系地址： -->
          <el-form-item>
            <p class="f14 c3">选择取消原因：</p>
            <div
              class="item"
              v-for="(item, index) in cancleListArr"
              :key="index"
              @click="changeSex(index)"
            >
              <img
                v-if="ruleForm.reason == index"
                src="@/assets/images/home/correct.png"
                alt=""
              />
              <img src="@/assets/images/home/no-correct.png" v-else alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-form-item>

          <!-- 联系地址：结束 -->
          <!-- 详细地址: -->
          <el-form-item required prop="details">
            <p class="f14 c3 detail-contant">请输入详细原因：</p>
            <el-input
              type="textarea"
              v-model="ruleForm.details"
              placeholder="请输入详细原因"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 详细地址:结束 -->
          <!-- 详细地址: -->
          <el-form-item class="btn">
            <button class="cancle" @click.prevent="cancle">取消</button>
            <button class="submit" @click.prevent="submitForm('ruleForm')">
              确认提交
            </button>
          </el-form-item>
          <!-- 详细地址:结束 -->
        </el-form>
        <!-- 表单结束 -->
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
    <el-dialog
      top="0"
      :visible.sync="detailDialog"
      width="750px"
      :before-close="handleDetailClose"
      :show-close="false"
      custom-class="detail-dialog-box"
    >
      <p slot="title" class="title">
        <span class="fs18 font-33">合同详情</span>
        <img
          src="@/assets/images/home/close.png"
          @click="detailDialog = false"
          class="p"
        />
      </p>
      <div class="form-box">
        <el-form
          label-width="100px"
          :model="ruleDetailForm"
          :status-icon="false"
          :rules="rulesDetail"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!-- 合同金额 -->
          <el-form-item label="合同金额:" required prop="id">
            <el-input
              type="number"
              maxlength="20"
              disabled
              v-model="ruleDetailForm.id"
              placeholder="请输入"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 合同金额 -->
          <!-- 上传合同附件 -->
          <el-form-item label="上传合同附件:" required prop="file">
            <a
              style="position: relative; top: 10px"
              :href="pdfUrl"
              target="_blank"
              >{{ pdfFile }}</a
            >

            <!-- <img
              class="file-img"
              src="@/assets/images/home/jichuang.png"
              alt=""
            /> -->
          </el-form-item>
          <!-- 上传合同附件 -->
          <!-- 设备类型结束 -->
          <!-- 备注：: -->
          <el-form-item class="mb20" label="备注:" required prop="remark">
            <el-input
              type="textarea"
              disabled
              v-model="ruleDetailForm.remark"
              placeholder=""
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 备注：:结束 -->
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validateDetails = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细原因"));
      }
      callback();
    };
    //合同详情验证
    var validateIdDetail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细原因"));
      }
      callback();
    };
    var validateRemarkDetail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入备注内容"));
      }
      callback();
    };
    return {
      detailDialog: false, //详情对话框
      ruleForm: {
        reason: 0,
        details: "",
      },
      rules: { details: [{ validator: validateDetails, trigger: "blur" }] },
      assderVisible: false, //客服弹窗
      orderNum: "", //订单编号
      type: "", //所选设备类型
      mobel: "", //设备型号
      compName: "", //公司名称
      comps: [], //设备所有类型
      status: "", //所选订单状态
      statusArr: [], //订单所有状态
      tableData: [], //表格数据
      ruleDetailForm: {
        id: "",
        file: "", //合同附件
        remark: "该用户服务需要工程师进行驻场", //备注
      }, //合同详情弹框
      rulesDetail: {
        id: [{ validator: validateIdDetail, trigger: "blur" }],
        remark: [{ validator: validateRemarkDetail, trigger: "blur" }],
      },

      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,

      cancleListArr: [],

      paramData: {}, //列表参数

      totalNum: 0, //设备总数

      // states: [],

      // 设备类型选择器
      props1: { value: "id", label: "typeName", children: "childrenList" },
      options1: [],
      posValue1: [],
      choseType: [],

      pdfFile: "",
      pdfUrl: "",
    };
  },

  watch: {
    // 订单状态改变
    status() {
      console.log("status改变了", this.status, typeof this.status);
      this.page = 1;
      this.paramData = {
        ...this.paramData,
        // orderNum: this.orderNum, //订单编号
        // deviceModel: this.mobel, //设备型号
        // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
        // companyName: this.compName, //公司名称
        status: Number(this.status), //订单状态
      };
      // console.log(this.paramData);
      // 报修订单-分页列表查询
      this.repairOrderList();
    },
    // 设备类型改变
    type() {
      // console.log(
      //   "status改变了",
      //   this.type,
      //   typeof this.type,
      //   "-----------------",
      //   Number(this.type)
      // );
      this.page = 1;
      if (this.type.length == 1) {
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // deviceTypeId: this.type ? this.type : "",
          deviceTypeParentId: this.type[0],
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
      } else {
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // deviceTypeId: this.type ? this.type : "",
          deviceTypeParentId: this.type[0],
          deviceTypeId: this.type[1],
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
      }

      // console.log(this.paramData);
      // 报修订单-分页列表查询
      this.repairOrderList();
    },
    // 订单编号改变
    orderNum() {
      if (this.orderNum != "") {
        // console.log("订单编号改变了并不为空");
        // 订单编号改变了并不为空
        this.page = 1;
        this.paramData = {
          ...this.paramData,
          orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
        // console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
      if (this.orderNum == "") {
        console.log("删除公司配置");
        delete this.paramData.orderNum;
        console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
    },
    // 设备型号改变
    mobel() {
      if (this.mobel != "") {
        // console.log(this.mobel, typeof this.mobel);
        // console.log("订单编号改变了并不为空");
        // 订单编号改变了并不为空
        this.page = 1;
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
        // console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
      if (this.mobel == "") {
        console.log("删除公司配置");
        delete this.paramData.deviceModel;
        console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
    },
    // 公司名称改变
    compName() {
      if (this.compName != "") {
        // console.log("订单编号改变了并不为空");
        // 订单编号改变了并不为空
        this.page = 1;
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
        // console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
      if (this.compName == "") {
        console.log("删除公司配置");
        delete this.paramData.companyName;
        console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
    },
  },

  mounted() {},

  created() {
    this.paramData = { pageNo: this.page, pageSize: this.pageSize };

    // 报修订单-分页列表查询
    this.repairOrderList();

    // 取消原因-分页列表查询
    this.cancleList();

    // 查询全部设备类型
    this.getAllType();

    // 状态分类统计
    this.getAllState();

    // 状态分类统计状态
    // this.getAllState();
  },

  methods: {
    // 设备类型改变
    posHandle1(a) {
      console.log(a, "设备类型");
      this.type = a;
      // this.choseType = a;
      // this.ruleForm.deviceTypeId = a.join(",");
      // console.log(this.ruleForm.deviceTypeId);
    },
    // 状态分类统计状态
    // getAllState() {
    //   this.$API
    //     .getAllState()
    //     .then((res) => {
    //       if (res.code == 1) {
    //         res.data.forEach((item) => {
    //           // this.states.push({ value: item.statusName, id: item.status });
    //           // if (item.status == 0) {
    //           //   this.startNum = item.num;
    //           // }
    //           // if (item.status == 1) {
    //           //   this.stopNum = item.num;
    //           // }
    //           // if (item.status == 2) {
    //           //   this.repairNum = item.num;
    //           // }
    //           this.totalNum += item.num;
    //           console.log(this.totalNum);
    //         });
    //       } else {
    //         this.$message.info(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // 状态分类统计
    getAllState() {
      this.$API
        .getAllOrderState()
        .then((res) => {
          // this.statusArr = res.result.map((item) => {
          //   if (item.value == "1") {
          //     item.text = "待指派";
          //     item.title = "待指派";
          //   }
          //   return item;
          // });
          if (res.code == 1) {
            this.statusArr = res.data.map((item) => {
              // if (item.value == "1") {
              //   console.log("===========");
              //   item.text = "待指派";
              //   item.title = "待指派";
              // }
              // if (item.value == "3") {
              //   console.log("===========");
              //   item.text = "服务中";
              //   item.title = "服务中";
              // }
              return item;
            });
            // res.data.forEach((item) => {
            // this.states.push({ value: item.statusName, id: item.status });
            // if (item.status == 0) {
            //   this.startNum = item.num;
            // }
            // if (item.status == 1) {
            //   this.stopNum = item.num;
            // }
            // if (item.status == 2) {
            //   this.repairNum = item.num;
            // }
            // this.totalNum += item.num;
            // });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // 状态分类统计
      this.$API
        .getStatusNum()
        .then((res) => {
          this.states = res.result;
          if (res.code == 1) {
            res.data.forEach((item) => {
              if (item.status == 0) {
                this.startNum = item.num;
              }
              if (item.status == 1) {
                this.stopNum = item.num;
              }
              if (item.status == 2) {
                this.repairNum = item.num;
              }
              this.totalNum += item.num;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.$API
      //   .getAllState()
      //   .then((res) => {
      //     if (res.code == 1) {
      //       this.statusArr = res.data;
      //       res.data.forEach((item) => {
      //         // this.states.push({ value: item.statusName, id: item.status });
      //         // if (item.status == 0) {
      //         //   this.startNum = item.num;
      //         // }
      //         // if (item.status == 1) {
      //         //   this.stopNum = item.num;
      //         // }
      //         // if (item.status == 2) {
      //         //   this.repairNum = item.num;
      //         // }
      //         this.totalNum += item.num;
      //       });
      //     } else {
      //       this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // 查询全部设备类型
    getAllType() {
      this.$API
        .getAllType()
        .then((res) => {
          if (res.code == 1) {
            // this.comps = res.data;
            this.options1 = res.data.map((item) => {
              // 第一级，市
              // item.areaTrees 第二级，区
              item.childrenList = item.childrenList.map((secondItem) => {
                // secondItem
                // secondItem.childrenList = secondItem.childrenList.map(
                //   (thirdItem) => {
                //     delete thirdItem.childrenList;
                //     return thirdItem;
                //   }
                // );
                delete secondItem.childrenList;
                return secondItem;
              });

              return item;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消原因-分页列表查询
    cancleList() {
      this.$API
        .cancleList()
        .then((res) => {
          if (res.code == 1) {
            this.cancleListArr = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 报修订单-分页列表查询
    repairOrderList() {
      this.$API
        .repairOrderList(this.paramData)
        .then((res) => {
          //   num: "001",
          // comp: "白白有限公司",
          // modal: "XCDX-199",
          // brand: "西门子",
          // status: "服务中",
          // date: "2021/10/10",
          // handle: "2",
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.tableData = [];
            res.data.records.forEach((item, index) => {
              this.tableData.push({
                num: item.orderNum, //订单编号
                comp: item.companyName, //公司
                modal: item.deviceModel, //设备型号
                brand: item.brandName, //设备品牌
                status: item.statusName, //订单状态
                date: item.createTime, //下单时间
                id: item.id,
                orderStatus: item.orderStatus, //订单状态值
                contractMoney: item.contractMoney, //合同金额
                workOrderCount: item.workOrderCount, //维保记录数量  为0不显示维保记录   不为零显示维保记录
                contractFileUrl: item.contractFileUrl,
                contractMoney: item.contractMoney,
                contractRemarks: item.contractRemarks,
                deviceId: item.deviceId,
              });
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //对话框处理函数
    handleDetailClose() {
      this.detailDialog = false;
    },
    // 取消
    cancle() {
      this.assderVisible = false;
      this.ruleForm.reason = 0;
      this.ruleForm.details = "";
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramData = {
            cancelId: this.cancleListArr[this.ruleForm.reason].id, //取消id
            cancelExplain: this.ruleForm.details, //取消说明
            id: Number(this.id),
            orderStatus: 99,
          };
          console.log(paramData, "paramdata");
          this.$alert("确定要取消吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: true,
            callback: (action) => {
              // 确定  confirm  取消 cancel
              if (action == "confirm") {
                this.$API
                  .cancleRepairOrder(paramData)
                  .then((res) => {
                    if (res.code == 1) {
                      this.$message({
                        message: "取消成功",
                        type: "success",
                      });
                      // 报修订单-分页列表查询
                      this.repairOrderList();
                      setTimeout(() => {
                        this.assderVisible = false;
                      }, 500);
                    } else {
                      this.$message.info(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            },
          });

          // alert("submit!");
        } else {
          // console.log("error submit!!");
          // return false;
        }
      });
    },
    // 改变性别
    changeSex(index) {
      this.ruleForm.reason = index;
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 维保记录
    handleWeibao(index, item) {
      // console.log(item);
      this.$router.push({ name: "repairrecord", query: { id: item.deviceId } });
    },
    // 合同详情
    handleDetails(index, row) {
      console.log(row, "row");
      this.ruleDetailForm.id = row.contractMoney;
      let fileArr = row.contractFileUrl.split("/");
      this.pdfFile = fileArr[fileArr.length - 1];
      this.pdfUrl = row.contractFileUrl;
      this.ruleDetailForm.remark = row.contractRemarks;
    },

    // 查看详情
    handleEdit(index, row) {},
    // 取消订单
    handleCancle(index, row) {
      // console.log(row, "row");
      this.id = row.id;
    },
    // 评价
    handleCommit(index, row) {},
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      console.log("page", this.page);
      this.paramData = {
        ...this.paramData,
        pageNo: this.page,
      };
      this.repairOrderList();
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 导出处理
    exportHandle(fileName) {
      let paramData = {};
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.queryParam };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["selections"] = this.selectedRowKeys.join(",");
      }
      console.log("导出参数", paramData);
      this.$API.exportOrder(paramData).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "text/csv,charset=UTF-8" }),
            fileName + ".xls"
          );
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: "text/csv,charset=UTF-8" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
        // 组装a标签
        let elink = document.createElement("a");
        // 设置下载文件名
        elink.download = "支出汇总表.xls";
        elink.style.display = "none";
        //  type: "text/csv,charset=utf-8",
        // let blob = new Blob([data], {
        //   type: "text/csv,charset=utf-8",
        // });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        window.URL.revokeObjectURL(blob);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.query-item{
  margin-top: 12px;
  margin-right: 22px;
}
.order-contaner {
  box-sizing: border-box;
  padding: 32px;
  // 设备列表
  .list {
    .title {
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
  }
  // 订单编号
  .input {
    display: inline-block;
    // margin-right: 32px;
    span {
      font-size: 14px;
      color: #333333;
      // margin-right: 20px;
    }
    .el-input {
      width: 160px;
      height: 40px;
      margin-left: 20px;
    }
  }
  .compName {
    // margin-right: 0px;
  }
  // 选择公司
  .check {
    // margin-top: 32px;
    // margin-left: 32px;
    display: inline-block;
  }
  .company {
    .name {
      // margin-right: 20px;
    }
    // .el-select {
    //   width: 160px;
    //   margin-left: 10px;
    // }
    .el-cascader {
      width: 160px;
      margin-left: 10px;
    }
  }
  //选择订单状态
  .order-box {
    // margin-top: 32px;
    // margin-left: 32px;
    display: inline-block;
  }
  .order {
    .name {
      // margin-right: 20px;
    }
    .el-select {
      width: 160px;
      margin-left: 10px;
    }
  }
  // 按钮
  .search {
    width: 94px;
    height: 40px;
    color: #ffffff;
    line-height: 7px !important;
    background: #ed1b23;
    border-radius: 4px 4px 4px 4px;
    margin-left: 230px;
  }
  //   当前设备
  .current-box {
    margin-top: 22px;
    margin-bottom: 12px;
    .num {
      color: #999999;
      margin-bottom: 10px;
      span {
        color: #1577fc;
      }
    }
  }
  // 表格
  .table-box {
    .see {
      margin: 5px;
      color: #007bd9;
    }
    .cancle {
      margin: 5px;
      color: #ff0015;
    }
    .commit {
      margin: 5px;
      color: #4aa800;
    }
    margin-bottom: 22px;
    .handle {
      ul {
        padding: 0 20px;
        display: flex;
        align-items: center;
        text-align: center;
      }
      li {
        // float: left;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #007bd9;
        line-height: 22px;
        margin-right: 16px;
        &.cancle {
          color: #ff0015;
        }
        &.assess {
          color: #4aa800;
        }
      }
    }
  }
  // 分頁
  .el-pagination {
    text-align: right;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }
  // 自定义弹窗样式
  .assderTc {
    // display: flex;
    // align-items: center;
    // .title {
    //   padding-top: 32px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   font-size: 22px;
    //   font-weight: bold;
    //   color: #333333;
    //   img {
    //     width: 32px;
    //     height: 32px;
    //     position: absolute;
    //     top: 32px;
    //     right: 32px;
    //   }
    // }
    // ::v-deep .el-dialog__header {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog {
    //   border-radius: 0 !important;
    // }
    // ::v-deep .el-dialog__body {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog__footer {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog {
    //   border-radius: 10px;
    // }
    // .ja-c {
    // }

    // .btnad {
    //   width: 112px;
    //   height: 40px;
    //   background: rgba(246, 247, 249, 1);
    //   border: 1px solid #ecedef;
    //   opacity: 1;
    //   border-radius: 20px;
    // }
    // .btnsad {
    //   color: #333;
    //   width: 112px;
    //   height: 40px;
    //   background: rgba(255, 195, 44, 1);
    //   opacity: 1;
    //   border-radius: 20px;
    // }
  }

  .camera {
    white-space: nowrap;
  }
  .el-form {
    padding: 0px !important;
  }
  .el-form-item__content {
    line-height: normal;
  }

  .el-dialog__body {
    height: 450px;
    padding: 0px;
  }
  .el-dialog__header {
    padding: 0px;
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 308px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-size: 22px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .demo-ruleForm {
    margin-top: 16px;
    margin-left: 32px;
    margin-bottom: 36px;
    // 性別
    .item {
      margin-top: 24px;
      // display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .detail-contant {
      margin-top: 30px;
      margin-bottom: 16px;
    }
  }
  .el-textarea__inner {
    width: 686px;
    height: 128px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #999999;
  }
  .btn {
    margin-top: 56px;
    .cancle {
      width: 214px;
      height: 50px;
      background: rgba(255, 0, 21, 0.13);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ff0e1e;
      margin-left: 105px;
      color: #ff0015;
      font-size: 16px;
    }
    .submit {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      margin-left: 48px;
      color: #ffffff;
      font-size: 16px;
    }
  }
  // 详情对话框
  .detail-dialog-box {
    box-sizing: border-box;
    padding: 32px;
    .form-box {
      // 性別
      .item {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .item2 {
        margin-left: 56px;
      }
      .file-img {
        width: 94px;
        height: 94px;
        margin-right: 10px;
      }
    }
    .title {
      text-align: center;
      padding-left: 345px;

      p {
        color: #333333;
        font-size: 22px;
      }
      img {
        width: 32px;
        height: 32px;
        // position: relative;
        // top: 8px;
        // right: -300px;
      }
    }
    //对话框垂直居中
    top: 50% !important;
    transform: translateY(-50%);
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 20px;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 536px;
      height: 104px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
    }
  }
}
</style>
